import React from "react"

import CenteredLayout from "../components/CenteredLayout"
import SEO from "../components/seo"

import "./comingSoon.scss";


const ComingSoonPage = () => (
    <CenteredLayout>
        <article className="coming-soon">
            <div className="horizontal-center">
                <SEO title="Coming Soon" keywords={[`brief therapy`, `San Francisco`, `Counselor`, `MFT`]} />
                <h1 className="logo">Gloria Díaz</h1>
                <p>Serving families, couples and individuals</p>
                <h2>Coming Soon...</h2>
                <h4>In the meantime, you can find me in <a href="https://www.psychologytoday.com/us/therapists/gloria-diaz-san-francisco-ca/136957">Psychology Today</a></h4>
            </div>
            <div className="footer">
                <small>Photo by Ryan Moreno on Unsplash</small>
            </div>
        </article>
  </CenteredLayout>
)

export default ComingSoonPage
