import React from "react"
import PropTypes from "prop-types"

import "./centeredLayout.scss";

const CenteredLayout = ({ children }) => (
    <div className="container">
        <main className="centered-layout">{children}</main>
    </div>
)

CenteredLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CenteredLayout
